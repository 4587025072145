<template>

  <div>
      <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
        <v-card>
        
        <v-row class="py-1 px-4" justify="space-between">
        <h3> All Over Report</h3>
        </v-row>
    
      <v-row class='pl-4'>
        <v-col md="2">
          <label>Company  &nbsp;<small style="color:red;">*</small></label>
          <v-autocomplete :items="company_list" item-value="id" item-text="name" v-model="editedItem.company" @change="getoffercode"> </v-autocomplete>
        </v-col>

        <v-col md="2">
         <label>Academic Year  &nbsp;<small style="color:red;">*</small></label>
          <v-autocomplete  :items="ay_list" item-value="id" item-text="name" v-model="editedItem.ay" @change="getoffercode"></v-autocomplete>
        </v-col>

        <v-col md="2">
           <label>Offering Code  &nbsp;<small style="color:red;">*</small></label>
          <v-autocomplete autocomplete="off"  :items="code_list" item-text="companyofferingcode" item-value="id" v-model="editedItem.offering"  @change="getRound"></v-autocomplete>
        </v-col>

        <v-col md="2">
           <label>Round  &nbsp;<small style="color:red;">*</small></label>
          <v-autocomplete   :items="round_list" item-text="round" item-value="id" v-model="editedItem.round"></v-autocomplete>
        </v-col>
        
        <v-col sm="2">
          <v-btn class="mt-6" color="primary darken-1" @click="fetchReport">Fetch Report</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <br />
  
    <!-- {{report_list}} -->
    <div>          
          <v-data-table :headers="headers" :items="report_list" class="elevation-1" :search="search" v-if="report_list.length!=0">
        <template v-slot:top>

          <v-toolbar flat color="white" align="center" justify="center">
            <v-toolbar-title>Report</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <download-excel
              class="mar"
              v-if="report_list.length != 0"
              color="primary"
              dark
              :data="report_list"
              :fields="table_fields"
              worksheet="My Worksheet"
              name="Placement Report.xls"
            >
               <v-btn color="success" dark class="mb-2">
                <v-icon dark>mdi-file-excel</v-icon>
              
                <!-- <v-icon right>mdi-download</v-icon> -->
              </v-btn>
            </download-excel>
          </v-toolbar>
        </template>
      </v-data-table>
    </div>
    
  
</div>
</template>
<script>
import axios from "axios";

export default {
  
  data: () => ({
     snackbar_msg: "",
    color: "",
    snackbar: false,
    company_list:[],
    ay_list:[],
    offer_list:[],
    round_list:[],
    company_id:"",
    code_list:[],
    round_list:[],
    round_details:[],
    round_no:"",
    corl_list:[],
    cor_data:[],
    offer_id:"",
    email_id: [],
company_code:[],
report_list:[],
branch: [],
search: "",
 selectall: false,


 headers: [
      { text: "Sr. No.", value: "sr_no", sortable: true },
      { text: "PRN/GR.No.", value: "reg_no", sortable: true },
      { text: "Name", value: "name", sortable: true },
      { text: "Mobile No.", value: "mobile", sortable: true },
        { text: "Email", value: "email", sortable: true },
      { text: "Company", value: "company_name", sortable: true },
         { text: "Offer code", value: "offer_code", sortable: true },
         { text: "Round name", value: "round_name", sortable: true },
            { text: "Branch", value: "branch", sortable: true },
    ],
 table_fields: {
      "Sr. No.": "id",
      "GR. No.": "reg_no",
      Name: "name",
      Company: "company_name",
      "Email": "email",
      "Offer code":"offer_code",
      "Round name": "round_name",
      Branch: "branch"
     
     
    },

    editedItem: {
      company:"",
       ay:"",
    offer:"",
    round:"",
      
    },
    defaultItem: {
     
    },
   
  }),

 

  mounted() {
    axios
      .get("/TPO/overAllReport")
       .then(res =>{
    
        if (res.data.msg == "200"){
             this.company_list = res.data.company_list;
           this.ay_list = res.data.ay_list.sort();
          this.offer_list = res.data.offer_list;
          this.round_list = res.data.round_list;
           this.round_no = res.data.round_no;
             this.round_details = res.data.round_details;
           
        }
        })
       
       },
       
  methods: {
    
   getoffercode()
    {
     // alert("hellooo"+this.editedItem.company+"ay"+this.editedItem.ay)
      if(this.editedItem.company&&this.editedItem.ay)
      {
         var params={company_id:this.editedItem.company,ay:this.editedItem.ay}
         axios
          .post("/TPO/getoffercode",params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.code_list = res.data.codelist;
               this.corl_list = res.data.corl_list;
            this.cor_data = res.data.cor_data;
            }});
            //  this.items = response.data;
      }
    },


    getRound(){
      
      //alert("in "+this.editedItem.offering)
       if(this.editedItem.offering)
      {
         var params={"offering_id":this.editedItem.offering}
         axios
          .post("/TPO/getRound",params)
          .then((res) => {
            if (res.data.msg == "200") {
              
              this.round_list=res.data.round_list;
              this.offer_id = res.data.offer_id;
            //    this.corl_list = res.data.corl_list;
            //    console.log( res.data.corl_list);
            // this.cor_data = res.data.cor_data;
            
            this.round_no = res.data.round_no;
               this.round_details = res.data.round_details;
            //  if(res.data.round_details == "Final Selected")
            //  {
            //     this.round_no = res.data.round_no;
            //    this.round_details = res.data.round_details;
            //  }
            
            }});
            //  this.items = response.data;
      }
    },
     showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

   fetchReport(){
    //  alert("this.editedItem.round "+this.editedItem.round)

      if(this.editedItem.round)
      {
         var params={"offer_id":this.offer_id,"round":this.editedItem.round}
         axios
          .post("/TPO/fetchoverallReport",params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.report_list=res.data.reportlist;
            
              if(this.report_list.length==0){
                     this.showSnackbar(
                  "#b71c1c",
                  "No students found for selected criteria!"
                );
              }
            }
            });
            //  this.items = response.data;
         }
      }

  }
  
  
};
</script>


